import { Ax } from '@/utils';
import formsSetVariantAttrMixin from './forms-set-variant-attr-mixin';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
import BpI18nHandlerMixin from './bp-i18n-handler-mixin';
import Decimal from 'decimal.js';

//  ***************************************** //
//      Миксин для форм без расшифровок       //
//  ***************************************** //
export default {
    mixins: [formsSetVariantAttrMixin, BpI18nHandlerMixin],
    data() {
        return {
            openDisabled: false,
            budgetForm: [],
            header: null,
            listUnit: [],
            unitNameLoading: false,
            load: false,
            isLoad: false,
            variantName: null,
            dataTypeFilter: null,
            formFilter: null,           
            progress: 0,
            isReportUploading: false,
            selectAll: false,
            userHasSeveralGus: false,
            guListLen: 0,
            formsList: ['133', '154', '155', '156', '157', '158', '163', '165', '212', '213', '311', 
            '321', '322', '331', '332', '336', '338', '352', '411', '412', '417', '418', '419', 
            '421', '422', '423', '511', '513', '514', '519', '612', '711', '712', '714', '812', '813', '814', 
            '815', '01-322', '02-322', '01-339', '02-339', '02-169'], 
            noSigners: [],
            formName: null, 
            spfName: null,
            budgetFormNoPpr: [],
            budgetFormToCopy: [],
            filterData: null,
            allFiles: [],
            isGkkpMode: null,
            isParentGuExist: false,
            headGuMinlevels: ['1', '3', '10'],
            isHeadGu: false,
            paidGkkpForms: ['03-149', '04-151', '02-159', '01-169', '418'],
            curGkkp: null
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.$refs.budgetHeader.urlChanged(to);
        next()
    },

    methods: {
        async changeHeader(data) {
            try {
                this.openDisabled = true;
                const curHeader = data;
                this.filterData = curHeader;
                if ((curHeader !== null)
                    && (curHeader.dataType !== null)
                    && (curHeader.gr !== null)
                    && (curHeader.gu !== null)
                    && (curHeader.prg !== null)) {
                    this.header = {
                        form: curHeader.form.code,
                        year: curHeader.yearProp,
                        cur_year: curHeader.year,
                        data_type: curHeader.dataType.code,
                        gu: curHeader.gu.code,
                        id_region: curHeader.gu.id_region,
                        gr: curHeader.gr.gr,
                        abp: curHeader.abp.abp,
                        prg: curHeader.prg.prg,
                        ppr: curHeader.ppr,
                        spf: curHeader.spf.spf,
                        region_code: curHeader.region,
                        variant: curHeader.budgetVersion.variant_uuid,
                        variant_date_time: curHeader.budgetVersion.date_start,
                        variant_end_date: curHeader.budgetVersion.end_date,
                        user_name: this.$store.state.user.sub,
                        mode: curHeader.mode,
                        gkkp: curHeader.gkkp,
                        first_date_of_year_gu: curHeader.dataType.firstDateOfYear,
                    };
                    this.formName = curHeader.form;
                    this.spfName = curHeader.spf;
                    let filteredGuList = curHeader.guList;
                    this.isGkkpMode = this.header.mode && this.header.mode === 'gkkp';
                    if (curHeader.region.slice(-4) !== '0101') {
                        filteredGuList = curHeader.guList.filter(itm => itm.id_region === curHeader.region)
                    }
                    this.guListLen = filteredGuList ? filteredGuList.length : 0;
                    if (curHeader.gu.budget_type === '02') {
                        this.header.id_region = curHeader.region;
                    }
                    this.dataTypeFilter = curHeader.dataType;
                    this.formFilter = this.form.code + ' - ' + this.form.name_ru;
                    
                    if ('curGkkp' in curHeader) {
                        this.curGkkp = curHeader.curGkkp;
                    }

                    // если атрибут поля attribute в budget_variants имеет значение true - редактирование формы возможно,
                    // если значение false/null - редактирование запрещено
                    this.setVariantAttribute(curHeader.budgetVersion.attribute, this.header, '004.002.004')
                    // this.variantAttribute = curHeader.budgetVersion.attribute;
                    // console.log('НЕ ЗАБУДЬ УДАЛИТЬ')
                    // setTimeout(() => this.variantAttribute = true, 1000)
                    this.setIsHeadGu(curHeader.gu.minlevel);
                    
                    this.variantName = curHeader.budgetVersion;
                    this.setDataForBreadCrumbs(this.header, curHeader);
                    this.progress = 30;
                    this.getIfParentGuExist();
                    await this.loadSpecificData();
                    
                    // this.progress = 60;
                    const timeout1 = setTimeout(() => this.progress = 50, 100)
                    const timeout2 = setTimeout(() => this.progress = 70, 100)
                    const timeout3 = setTimeout(() => this.progress = 80, 100)
                    
                    await Promise.all(
                        [this.loadDatas(),
                        this.loadDatasPaid()]
                    );
                    clearTimeout(timeout1);
                    clearTimeout(timeout2);
                    clearTimeout(timeout3);
                    this.progress = 100;
        } 
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('params_updt'));
            } finally {
                this.openDisabled = false;
            }
        }, // обновление параметров

        setDataForBreadCrumbs(header, curHeader) {
            this.$emit('setDataForBreadCrumbs', header, curHeader)
        },

        async loadSpecificData() {
            // при необходимоси добавить в форме
        },

        async loadDatasPaid() {
            // используется для форм с вкладками
        },

        async checkSignatories(batch = false, oneGu = false) {
            this.header.code_modules = this.moduleCode;
            this.header.batch = batch;
            this.header.oneGu = oneGu;
            try {
                const response = await fetch('/api-py/check-signatories/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    if (result > 0) {
                        this.showSignersModal(result)
                        // this.$refs.modalSignatories.showModal();
                    }
                } else {
                    this.makeToast('danger', this.getErrText('subsc'), `${response.status} error`);
                }
            } catch (error) {
                this.makeToast('danger', this.getErrText('subsc'), error.toString());
            }
        }, // проверка наличия подписантов перед скачиванием

        showSignersModal(forms) {
            this.$bvModal.msgBoxOk(this.getCommonText('no_subsc'), {
              size: 'lg',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: this.getCommonText('close'),
              headerClass: 'p-2',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
              .then(value => {
              })
              .catch(err => {
                // An error occurred
              })
          },
        
        downloadRep() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            this.header.isGkkpPaid = false;
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.getCommonText('form')} ` + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadRep()`, error.toString());
                }
            );
        },

        downloadBatchReports(oneGu, type = null) {
            this.isReportUploading = true;
            this.$store.commit('setIsDownloadButtonEnabled');
            this.makeToast('info', this.getCommonText('attention'), this.getCommonText('upload'));
            this.header.lang = this.$i18n.locale;
            this.header.oneGu = oneGu === true;
            this.header.code_modules = this.moduleCode;
            this.header.rep_type = type;
            this.header.isGkkpPaid = false;
            Ax(
                {
                    url: '/api-py/budg_batch_reports/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    if (data && data.size > 0) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const guOrGkkp = this.header.mode === 'gkkp' ? this.getCommonText('gkkp') : this.getCommonText('gu');
                        const abpOrDep = type === 'edu' ? this.getCommonText('departament') : this.getCommonText('abp');
                        const fileName = this.getCommonText('butch_rep', {gu_gkkp_abp: oneGu === true ? guOrGkkp : abpOrDep})
                        link.setAttribute('download', `${fileName}.xls`);
                        document.body.appendChild(link);
                        link.click();
                        this.$store.commit('setIsDownloadButtonEnabled');
                    } else {
                        throw new Error('Error');
                    }
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadBatchReports()`, error.toString());
                    this.$store.commit('setIsDownloadButtonEnabled');
                }
            );
        },

        downloadBatchReportsPaid(oneGu, type = null) {
            this.isReportUploading = true;
            this.$store.commit('setIsDownloadButtonEnabled');
            this.makeToast('info', this.getCommonText('attention'), this.getCommonText('upload'));
            this.header.lang = this.$i18n.locale;
            this.header.oneGu = oneGu === true;
            this.header.code_modules = this.moduleCode;
            this.header.rep_type = type;
            this.header.isGkkpPaid = true;
            Ax(
                {
                    url: '/api-py/budg_batch_reports_paid/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    if (data && data.size > 0) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const fileName = this.getCommonText('butch_rep', {gu_gkkp_abp: this.getCommonText('gkkp') + '.' +this.getCommonText('paid_services_shrt')});
                        link.setAttribute('download', `${fileName}.xls`);
                        document.body.appendChild(link);
                        link.click();
                        this.$store.commit('setIsDownloadButtonEnabled');
                    } else {
                        throw new Error('Error');
                    }
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadBatchReportsPaid()`, error.toString());
                    this.$store.commit('setIsDownloadButtonEnabled');
                }
            );
        },

        keyPress(event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13(event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            this.load = true;
            try {
                const response = await fetch('/api-py/get-budget-request-form-datas/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                if (response.status === 200) {
                    const values = await response.json();
                    this.budgetForm.splice(0);
                    if (values && values.length > 0) {
                        values.forEach(val => {
                            this.setDatasParams(val)
                        });
                    }
                    else {
                        this.addItem();
                    }
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        }, 
        
        async selectedUnitChanged(item) {
            if (item.unit_inf == null || item.unit_inf == undefined) {
                this.$set(item, 'unit', null);
                this.$set(item, 'unit_inf', null);
            }
            else {
                this.$set(item, 'unit', item.unit_inf.code);
                this.$set(item, 'unit_inf', item.unit_inf);
            }
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.code === code) {
                    return item;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        async loadUnits() {
            try {
                const response = await fetch('/api-py/dictionary_units/');
                const values = await response.json();
                values.forEach(item => {
                    Object.defineProperty(item, 'text', {
                        get: () => {
                            return this.getUnitLockName(item);
                        }
                    });
                    this.listUnit.push(item);
                });
                this.listUnit = values.sort((a, b) => a.text > b.text ? 1 : -1)
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadUnits`, error.toString());
            }
        }, // справочник единиц измерения

        getUnitLockName(item) {
            if (item == null || item == undefined) return;
            if (this.lng === 'kk') {
                if (item.name_kz) {
                    return item.name_kz;
                } else {
                    return item.name_ru;
                }
            }
            return item.name_ru;
        },

        setDatasParams(val) {
            // заменить в родительском компоненте
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async save(values) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gu' ? '/api-py/save-brform' + this.form.code + '/' : '/api-py/save-brform-gkkp-headerinside/'
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if (response.status === 200) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    this.selectAll = false;
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        inputFixed(item, field, value, digit, defaultVal) {
            if (value === null || value === '' || value === '.' || !Number(value)) {
                this.inputFixedVldtn(item, field, value, digit, defaultVal);
                return;
            };
            if (field === 'number_months' || field === 'months') {
                this.monthValidation(value, item, field, digit);
                return;
            };
            if (field === 'amount_days') {
                this.yearValidation(value, item, field);
                return;
            };
            this.inputFixedVldtn(item, field, value, digit);
            // this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой
        
        inputFixedVldtn(item, field, value, digit, defaultVal = 0) {
            // nextTick добавлен тк не без него не работает реактивность при событии keyPress.enter если предыдущее значение = 0 
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? this.safeDecimal(value).toDecimalPlaces(digit).toNumber() : defaultVal;
                this.$set(item, field, newVal);
            })
        }, // форматирует введенное значение до digit цифр после запятой

        deleteItem(msg = null, row = false, index = false) {
            let message = `${this.getDecText('del_curr_rec')}...`;
            if (msg) {
                message = msg;
            }
            this.$bvModal.msgBoxConfirm(
                message,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            this.prepareForDelete(row, index);
                            if (row.id > 0) {
                                this.delete([row]);
                            }
                        } else {
                            this.prepareForDelete(row, index);
                        }
                        
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        },
        
        prepareForDelete(row, index) {
            // при необходимости заменить в ко
        },

        getFormCode() {
            // при необходимости заменить в родительском компоненте. Напр. Form01_142.vue
            return this.form.code;
        },
        
        deleteItemWithAttachedFiles(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        if (row) {
                            if (row.id > 0) {
                                await this.deleteWithAttachedFiles([row], [row.id], index);
                            } else {
                                this.budgetForm.splice(index, 1);
                            }
                        } else {
                            const rows = [];
                            const rowsId = [];
                            const newRowsToDelId = [];
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    if (item.id > 0) {
                                        rows.push(item);
                                        rowsId.push(item.id);
                                    } else {
                                        newRowsToDelId.push(item.id)
                                    }
                                }
                            });
                            if (newRowsToDelId.length) {
                                this.budgetForm = this.budgetForm.filter(item => !newRowsToDelId.includes(item.id));
                                if (!rows.length) this.selectAll = false;
                            }
                            if (rows.length) await this.deleteWithAttachedFiles(rows, rowsId, index);
                        }
                    this.afterDeleted(); // await для корректного срабатывания этой футкции
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        },

        afterDeleted() {
            // при необходимости дополнительных действий заменить в родителе
        },

        calcTotal(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total', ids));
        },

        async deleteWithAttachedFiles(items, ids, index) {
            this.isLoad = true;
            const dataToDel = {
                items,
                form: this.form.code
            }
            
            try {
                this.calcTotal(ids);
                const response = await fetch('/api-py/delete-budget-request-multiple-rows-with-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (index !== false) {
                        this.budgetForm.splice(index, 1);
                    } else {
                        this.budgetForm = this.budgetForm.filter(item => { return !item.itemToDelete });
                        this.selectAll = false;
                    }
                    this.makeToast('success', this.getErrText('msg'), index !== false ? this.getErrText('one_deleted') : this.getErrText('deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                } else {
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.isLoad = false;
            }
        }, // удаление данных

        deletingAgreementTotalResultHandler(result) {
            if ('agreement_deleting_result' in result) {
                const res = result.agreement_deleting_result;
                console.log('Service budget_request_total_agreement_delete has complited');
                console.log(`Result: ${res.result}`);
                if ('delete' in res) {
                    console.log(`Agreement total propably was deleted: ${res.delete}`);
                }
                if ('error' in res) {
                    console.log(`Error message: ${res.error}`);
                }
                console.log(`_______________________________`);
                console.log(``);

            }
            if ('agreement_deleting_result' in result && result.agreement_deleting_result.result === 'error') {
                const res = result.agreement_deleting_result;
                const errorDescr = 'error' in res ? res.error : '';
                this.makeToast('danger', 'budget-request-total-agreement-delete', `Ошибка ${errorDescr}`);
            }            
        },
        

        calcGoodsTotalBeforeDel() {
            // при необходимости заменить в родителе
            return this.budgetForm.length ? this.budgetForm.reduce((acc, sum) => sum.src_group ? acc : acc + sum.total, 0) : 0;
        },

        async deleteSeveralGoods(items, catDeleting = false) {
            const dataToDel = {
                items,
                header: { ...this.header, value: Math.ceil(this.calcGoodsTotalBeforeDel()) },
                form: this.getFormCode(),
                catDeleting,
            }
            try {
                const response = await fetch('/api-py/delete-several-goods/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if (response.status === 200 && result.result === 'success') {
                    if (!catDeleting) {
                        this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                    }
                    this.deletingAgreementTotalResultHandler(result);
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.selectAll = false;

            }
        },

        async delete(item) {
            if (!item.length) return;
            const dataToDel = {
                item,
                header: this.header,
                form: this.getFormCode()
            }
            try {
                const url = this.header.mode === 'gkkp' ? 'delete-list-budget-request-form-gkkp/' : 'delete-list-budget-request-form/' 
                const response = await fetch('/api-py/' + url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.form.code === '01-134') this.budgetForm.forEach(itm => itm.id = -1);
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                } else {
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            }
        }, // удаление данных

        nameIsEmpty(item, fields) {
            if (typeof fields === 'string') {
                fields = [fields];
            }

            let isAllFieldsEmpty = true;
            for (const field in fields) {
                isAllFieldsEmpty = isAllFieldsEmpty && (item[field] && item[field].trim() !== '');
            }
            return isAllFieldsEmpty;
        }, // возвращает true если все поля с названиями пустые
        
        checkForDoubles(data, field) {
            if (!data || !data.length) return;
            for (const itm1 of data) {
                const trimmedName1 = itm1[field] ? itm1[field].trim().toLowerCase() : '';
                const doubles = data.filter(itm2 => {
                    const trimmedName2 = itm2[field] ? itm2[field].trim().toLowerCase() : '';
                    return itm1.id !== itm2.id
                        && trimmedName1 === trimmedName2
                })
                if (doubles.length > 0) this.$set(itm1, 'isDouble', true)
                else this.$set(itm1, 'isDouble', false)
            }
        }, // поиск дублей по текстовому полю

        optionNotSelected(row, fieldNames, fields = []) {
            let flag = false;
            if (fields.length) {
                fields.forEach(field => {
                    const trimmedField = row[field] ? toString(row[field]).trim() : '';
                    if (trimmedField === '') {
                        const currField = fieldNames.find(itm => itm.key === field)
                        flag = true;
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('cant_be_empty', {fieldName: currField.label}));
                    }
                })
            }
            return flag
        },

        isSavingForbidden(row, fieldNames, numFields = [], strFields = []) {
            let flag = false;
            if (strFields.length) {
                strFields.forEach(field => {
                    const trimmedField = row[field] ? toString(row[field]).trim() : '';
                    if (trimmedField === '') {
                        const currField = fieldNames.find(itm => itm.key === field)
                        flag = true;
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('cant_be_empty', {fieldName: currField.label}));
                    }
                })
            }
            if (row.isDouble) {
                flag = true;
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('doubles'));
            }
            if (numFields.length) {
                numFields.forEach(field => {
                    if (row[field] <= 0) {
                        const currField = fieldNames.find(itm => itm.key === field)
                        flag = true;
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('cant_be_zero', {fieldName: currField.label}));
                    }
                })
            }
            
            return flag
        }, // возвращает true если есть дубли или одно из переданных полей <= 0

        onChangeName(newVal, data) {
            data.item.name_ru = newVal.trim();
            this.checkForDoubles(this.budgetForm, 'name_ru');
        },

        monthValidation(value, item, field, digit) {
            if (value === null || value === '' || value === '.' || !Number(value) || value < 1) {
                return;
            };
            const newVal = 12 < Number(value) ? 12 : Number(value);
            this.$set(item, field, this.safeDecimal(newVal).toDecimalPlaces(digit).toNumber());
        },

        yearValidation(value, item, field) {
            if (value === null || value === '' || value === '.' || !Number(value) || value < 1) {
                return;
            };
            let newVal = Number(value);
            if (this.header.year) {
                const daysInYear = this.getNumberOfDaysInYear(this.header.year);                
                newVal = daysInYear < newVal ? daysInYear : newVal;
            }
            this.$set(item, field, newVal);
        },

        getNumberOfDaysInYear(year) {
            const date = new Date(year, 11, 31); // December 31st of the given year
            return date.getUTCFullYear() % 4 === 0 && (date.getUTCFullYear() % 100 !== 0 || date.getUTCFullYear() % 400 === 0) ? 366 : 365;
        }, // возвращает кол-во дней для переданного года.

        calcTotalForDelete(items, calcField, ids = []) {
            let sum = 0;
            for (const row of items) {
                if (row.id > 0 && ids.length && !ids.includes(row.id)) {
                    sum += parseFloat(row[calcField]);
                } else if (row.id > 0 && !ids.length) {
                    sum += parseFloat(row[calcField]);
                }
            }
            return Math.ceil(sum);
        },

        setIsAllDelete(val) {
            this.budgetForm.forEach(item => this.$set(item, 'itemToDelete', val));
        },

        onFilesClick(item) {
            this.$refs.modalFilesManagement.showModal(item);
            this.rowId = item.id;
        }, // открытие модалки с файлами

        adjustDropdownMenuStyle() {
            const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                dropdownMenu.style.transform = 'translate3d(-180px, 30px, 0px)';
            }
        }, // смещение дропдауна скачивания отчетев для форм 02-159-1 и -2

        openFilterByRef(refName) {
            this.$refs.budgetHeader.openFilterByRef(refName, this.header.mode);
        },
        
        setProgress(val) {
            this.progress = val;
        },

        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                files.forEach((file) => {
                    const newFile = JSON.parse(JSON.stringify(file));
                    const isNotSelectedRow = file.row_id !== item.id;
                    if (isNotSelectedRow) {
                        newFile.row_id = null;
                        item['row_files'] = [...item['row_files'], newFile];
                    }
                    
                })
            })

            this.$refs.modalFilesManagement.setDataChanged(true);
            this.$refs.modalFilesManagement.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
        },

        triggDownloadFile(filename) {
            this.$refs.fileUpdown.downloadFile(filename);
        }, // инициация скачивания файла

        triggDownloadAllFiles(filesList) {
            const fileNamesList = filesList.reduce((arr, item) => {
                if (typeof item === 'string') {
                    arr.push(item);
                } else {
                    arr.push(item.file_name);
                }
                return arr;
            }, []
                );
            this.$refs.fileUpdown.downloadAllFileReqst(fileNamesList);
        }, // инициация скачивания прикрепленных файлов

        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },

        totalCalculation(array, field) {
            let sum = this.safeDecimal(0);
            for (const row of array) {
                if (row[field]) {
                    const total = this.safeDecimal(row[field]);
                    sum = sum.add(total);
                }        
            }
            return Math.ceil(sum.toNumber());
        }, 

        onFieldsValidationFailed() {
            this.makeToast('danger', this.getErrText('not_saved'), this.getErrText('bad_data_1'));
        },
        
        roundNumber(input, decimalPlaces) {
            const numberValue = Number(input) || 0;
            const decimalPlacesValue = Number(decimalPlaces) || 0;
            const rank = Math.pow(10, decimalPlacesValue);
            const mult = Math.round(numberValue * rank * 100) / 100;
            const roundedNumber = Math.round(mult) / rank;
            return roundedNumber;
        },

        //--------------  для форм 02-159_1 и _2 -------------------
        async loadRegions() {
            this.listRegPr.splice(0);
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl +'/'+ this.$store.getters.user_uuid);
                const result = await response.json();
                const items = this.filterDistricts(result, 'code');
                for (const row of items) {
                    if (row.code.slice(-2) !== '01') continue;
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'disabled', false);
                    Object.defineProperty(el, 'text', {
                        get: () => {
                            return row.code + " - " + row[`name_${this.lng}`];
                        }
                    });
                    this.listRegPr.push(el);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadRegions`, error.toString());
            }
        }, // справочник регионов

        dasableRegions(regionsList, formData) {
            if (!regionsList || !formData) return;
            for (const reg of regionsList) {
                const isRegionExist = formData.findIndex(itm => itm.src_region && itm.region && reg.value && itm.region.code === reg.value.code) !== -1;
                reg.disabled = isRegionExist;
            }
        }, // дисайблит регионы которые уже есть 

        hasRegionProjects(item) {
            const existingRegions = {}
            this.budgetForm.forEach(itm => {
                const regionCode = itm.region ? itm.region.code : null;
                if (itm.src_region && regionCode && !existingRegions[regionCode]) {
                    existingRegions[regionCode] = true
                }
            })
            const newBudgetForm = []
            this.budgetForm.forEach(itm => {
                const regionRow = itm.src_region
                const savedRow =  itm.id > 0
             
                if (regionRow || savedRow) {
                    newBudgetForm.push(itm);
                } else {
                    const regionCode = itm.region
                    const isHasRegion = regionCode && existingRegions[regionCode]
                    if (isHasRegion) newBudgetForm.push(itm);
                }
            })
            this.budgetForm = newBudgetForm;
        },

        filterDistricts(result, field) {
            if (this.header && this.header.abp === 268 && [25, 28].includes(this.header.prg)) {
                const newResult = result.filter(itm => itm[field].slice(-4) === '0101')
                return newResult
            }
            return result
        },
        
        isProjectHasSpendings(item) {
            let isHasSpndings = false;
            const spndingsRec = this.getCurrSpendingsRec(item);
            if (spndingsRec.length > 0) {
                this.$set(item, "hasSpendings", true)
                isHasSpndings = true;
            }
            return isHasSpndings
        }, // признак доступности проекта для редактирования

        getCurrSpendingsRec(item) {
            const projectCode = item.code;
            let spndingsRec = [];
            const isBudgetFormData = this.budgetFormNoPpr && this.budgetFormNoPpr.length;
            if (isBudgetFormData && projectCode) {
                spndingsRec = this.budgetFormNoPpr.filter(itm => itm.code_project && itm.code_project === projectCode);
            }
            return spndingsRec
        },

        onShowSpndings(item) {
            this.$refs.spendingsModal.showModal(this.getCurrSpendingsRec(item))
        },

        getDataToCopy() {
            this.budgetFormToCopy.splice(0);
            if (['02-159_1', '02-159_2'].includes(this.header.form) && this.prForm.length && this.budgetForm.length) {
                const actualNextYearPrjs = this.prForm.reduce((acc, val) => {
                    const isCurrPrjActualNxtYear = val.code && val.end_date && val.end_date >= `${this.header.year + 1}-01-01`
                    if (isCurrPrjActualNxtYear) {
                        return [...acc, val.code]
                    }
                    return acc
                }, [])
                if (actualNextYearPrjs.length !== 0) {
                    this.budgetFormToCopy = this.budgetForm.filter(item => actualNextYearPrjs.includes(item.code_project))
                }
            }
        },

        async loadDataWithoutPpr(filterData) {
            if (!filterData) return;
            this.budgetFormNoPpr.splice(0);
            const data = {
                guCurr: filterData.gu,
                gkkpCurr: this.curGkkp,
                curAbpList: filterData.curAbpList,
            }
            try {
                const response = await fetch(`/api-py/get-budget-request-form-02-159-1-2/` + JSON.stringify(this.header),
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    for (const el of result) {
                        el.name_ru = variantNameLangSupport(el, 'ru');
                        el.name_kk = variantNameLangSupport(el, 'kk');
                        el.name_en = variantNameLangSupport(el, 'en');
                    }
                    this.budgetFormNoPpr = result;
                } else {
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('copy_error'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('copy_error'));
            }
        }, // получение данных по пробнрамме без учета ppr, year, cur_year, data_type и пр

        isDataValid(row, rowNumber) {
            if (this.isEmpty(row.region)) return this.validationErrorMessage(this.getCommonText('region'), rowNumber);
            if (this.isEmpty(row.name_ru)) return this.validationErrorMessage(this.getCommonText('name'), rowNumber);
            if (this.isEmpty(row.unit)) return this.validationErrorMessage(this.getUnitsText('measure'), rowNumber);
            if (['001.006.008', '002.055'].includes(row.unit.code) && (row.distance === 0))  return this.validationErrorMessage(this.getCommonText('length'), rowNumber);
            if (this.isEmpty(row.beg_date)) return this.validationErrorMessage(this.getCommonText('beg_date'), rowNumber);
            if ((!this.isEmpty(row.beg_date) && !this.isEmpty(row.end_date) && (row.beg_date >= row.end_date))
                || this.isEmpty(row.end_date)) return this.validationErrorMessage(this.getCommonText('end_date'), rowNumber);
            if (row.checkForDuplicatesReg === true || row.checkForDuplicates === true) {
                this.makeToast('warning', this.getErrText('not_saved'), this.getErrText('doubles_in_row', {rowNumber: rowNumber}));
                return false
            }
            return true
        },

        isEmpty(elem) {
            if (!elem || elem === null) return true
            if (typeof elem === 'string' && elem.trim() === '') return true
            return false
        },

        validationErrorMessage(fieldName, rowNmb) {
            this.makeToast('warning', this.getErrText('not_saved'), this.getDecText('not_valid', {fieldName: fieldName, rowNmb: rowNmb}));
            return false
        },

        getText(link, field, extra = null) {
            const mainTextFieldName = link + field;
            if (extra) {
                if (typeof extra === 'number') return this.$tc(mainTextFieldName, extra)
                else return this.$t(mainTextFieldName, extra);
            };
            return this.$t(mainTextFieldName);
        },

        // ---------------------------------------------------------------------

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0;
                const v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }, // генерация уникального id

        roundNumber(number, decimalPlaces) {
            const numberAsFloat = typeof number === 'string' ? parseFloat(number) : number;
            
            if (!isNaN(numberAsFloat) && typeof numberAsFloat === 'number') {
                const multiplier = Math.pow(10, decimalPlaces);
                return Math.round(numberAsFloat * multiplier) / multiplier;
            };
            return 0;
        },   

        restetFilesList() {
            this.allFiles.splice(0);
        }, //добавил т.к. при перерисовке модуля загрузки файлов появляются дубли

        getUnicFilesArr(val) {
            if (!val.all_files) return [];
            const filesList = [];
            [...new Set(val.all_files.split(';'))].forEach(item => {
                if (item) filesList.push(item);
            });
            return filesList;
        }, // получаем масив с уникальными кодами прикрепленных файлов для категорий

        async getIfParentGuExist() {
            this.isParentGuExist = false;
            const curGu = this.header.gu;
            if (!this.isGuMode || !curGu) return;
                
            const varStartDate = this.header.variant_date_time;
            const varEndDate = this.header.variant_end_date;
            const userId = this.header.user_name;
            const formatedFirstDayOfYear = this.header.first_date_of_year_gu ? this.header.first_date_of_year_gu : null;
            try {
                const response = await fetch(`/api-py/get-if-parent-gu-exist/${curGu}/${userId}/${varStartDate}/${varEndDate}/${formatedFirstDayOfYear}`);
                if (response.status === 200) {
                    const result = await response.json();
                    this.isParentGuExist = result;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getIfParentGuExist`, error.toString());
            }
        },
        
        onEditCatName(row) {
            const modal = this.$refs.setCatNameModal;
            if (modal) modal.showModal(row);
        },

        onAddNewCat() {
            const modal = this.$refs.setCatNameModal;
            if (modal) modal.showModal();
        },

        onEditNote(row) {
            const modal = this.$refs.setNoteModal;
            if (modal) modal.showModal(row);
        },

        setIsHeadGu(minlevel) {
            this.isHeadGu = this.headGuMinlevels.includes(minlevel);
        },

        safeDecimal(value) {
            if (value === undefined || value === null || value === '') {
                return new Decimal(0);
            }
            return new Decimal(value);
        }
    },
    mounted() {
        this.progress = 15;
    },
    computed: {
        lang() {
            let lang = 'ru';
            if (this.$i18n.locale === 'kk') lang = 'kz';
            if (this.$i18n.locale === 'en') lang = 'en';
            return lang;
        },
        lng() {
            let lng = 'ru';
            if (this.$i18n.locale === 'kk') lng = 'kk';
            return lng;
        },
        localLableField() {
            return `lable_name_${this.lng}`;
        },
        moduleCode() {
            return this.formsList.includes(this.form.code) ? '004.002.005' : '004.002.004';
        },
        messages() {
            const msgs = {
                addRegion: 'Добавить район',
                addProject: 'Добавить проект'
            };
            if (this.$i18n.locale) {
                this.$set(msgs, 'addRegion', this.getCommonText('add_reg'));
                this.$set(msgs, 'addProject', this.getCommonText('add_prj'));
            }
            return msgs;
        },
        isGuMode() {
            return this.header && this.header.mode === 'gu';
        },
    }
}
